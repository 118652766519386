<template>
  <div class="upstreamTransportPriceCheck">
    <h1>上游运费校对</h1>
    <el-form :inline="true">
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="show.importShow = true">导入并校对</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-tooltip effect="dark" content="校对结果仅缓存10分钟，请尽快下载" placement="top">
            <el-button :disabled="!data.uuid || !data.failTransportList || data.failTransportList.length <= 0" @click="down()">导出校对失败的运费单</el-button>
          </el-tooltip>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-text>校对失败的采购单：</el-text>
        <el-table :data="data.failProcureList" border style="height: calc(50vh - 150px);">
          <el-table-column prop="sendDate" label="日期" width="120" show-overflow-tooltip />
          <el-table-column prop="truckNumber" label="车号" show-overflow-tooltip />
          <el-table-column prop="transportAmount" label="运费" show-overflow-tooltip />
        </el-table>
      </el-col>
      <el-col :span="12">
        <el-text>校对失败的运费单（Excel）：</el-text>
        <el-table :data="data.failTransportList" border style="height: calc(50vh - 150px);">
          <el-table-column prop="sendDate" label="日期" width="120" show-overflow-tooltip />
          <el-table-column prop="truckNumber" label="车号" show-overflow-tooltip />
          <el-table-column prop="transportAmount" label="运费" show-overflow-tooltip />
        </el-table>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 20px;">
      <el-col :span="12">
        <el-text>校对通过的采购单：</el-text>
        <el-table :data="data.successProcureList" border style="height: calc(50vh - 150px);">
          <el-table-column prop="sendDate" label="日期" width="120" show-overflow-tooltip />
          <el-table-column prop="truckNumber" label="车号" show-overflow-tooltip />
          <el-table-column prop="transportAmount" label="运费" show-overflow-tooltip />
        </el-table>
      </el-col>
      <el-col :span="12">
        <el-text>校对通过的运费单（Excel）：</el-text>
        <el-table :data="data.successTransportList" border style="height: calc(50vh - 150px);">
          <el-table-column prop="sendDate" label="日期" width="120" show-overflow-tooltip />
          <el-table-column prop="truckNumber" label="车号" show-overflow-tooltip />
          <el-table-column prop="transportAmount" label="运费" show-overflow-tooltip />
        </el-table>
      </el-col>
    </el-row>
  </div>

  <el-dialog v-model="show.importShow" title="确认导入" width="500">
    <el-form>
      <el-form-item>
        <el-text>选择一张</el-text>
        <el-link type="primary" @click="importExcelTemplate()">下载平台运费校对模板</el-link>
        <el-text>，填写模板内容再导入。</el-text>
        <br>
        <el-text type="danger">模板格式不对应可能造成倒入数据混乱，请注意。</el-text>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-upload :action="api.baseURL() + '/backend/procure/upstreamTransportPriceCheck'" :headers="api.tokenHeader()" :on-progress="importExcelProgress" :on-success="importExcelSuccess" :on-error="importExcelError" :show-file-list="false">
          <el-button type="primary">导入</el-button>
        </el-upload>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import apiNative from '../../apis/apiNative'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()

const data = reactive({
  uuid: null,
  successProcureList: [],
  successTransportList: [],
  failProcureList: [],
  failTransportList: [],
})

const show = reactive({
  importShow: false
})

const importExcelTemplate = () => {
  apiNative.get('/backend/procure/transportCheckImportTemplate', {
    responseType: 'blob'
  }).then(res => {
    ElMessage.success('开始下载')
    util.downloadFile(res, '平台运费校对模板', 'xlsx')
  }).catch(() => {
    ElMessage.error('下载失败，请联系系统管理员')
  })
}

const importExcelProgress = () => {
  ElMessage.success('正在导入！如果数据量大可能时间较长，请耐心等待。')
}

const importExcelSuccess = (res) => {
  if (res.code != 'S000001') {
    ElMessage.error('导入失败，请联系系统管理员')
    return
  }
  show.importShow = false
  let message = '<p>本次导入，共计识别到：' + res.data.importExcelResult.all + '条数据</p>'
  message += '<p>成功导入了：<span style="color:green"> ' + res.data.importExcelResult.success + ' </span>条数据</p>'
  message += '<p>失败了：<span style="color:red"> ' + res.data.importExcelResult.error + ' </span>条数据</p>'
  if (res.data.importExcelResult.error > 0) {
    for (const failIndex in res.data.importExcelResult.failRowList) {
      message += '<p>第 ' + res.data.importExcelResult.failRowList[failIndex].row + ' 行：' + res.data.importExcelResult.failRowList[failIndex].reason + '</p>'
    }
  }
  data.uuid = res.data.uuid
  data.successProcureList = res.data.successProcureList
  data.successTransportList = res.data.successTransportList
  data.failProcureList = res.data.failProcureList
  data.failTransportList = res.data.failTransportList
  ElMessageBox.alert('', {
    title: '导入完成',
    message: message,
    autofocus: false,
    dangerouslyUseHTMLString: true,
    confirmButtonText: '确定'
  })
}

const importExcelError = () => {
  ElMessage.error('导入失败，请联系系统管理员')
}

const down = () => {
  apiNative.get('/backend/procure/upstreamTransportPriceCheckDownload', {
    params: { uuid: data.uuid },
    responseType: 'blob'
  }).then(res => {
    ElMessage.success('开始下载')
    util.downloadFile(res, '上游运费校对失败' + util.parseTime(new Date(), '{y}{m}{d}{h}{i}{s}'), 'xlsx')
  }).catch(res => {
    console.log(res)
    ElMessage.error('导出失败，请联系系统管理员')
  })
}
</script>

<style lang="less"></style>